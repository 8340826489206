import * as React from 'react';
import { QuickCard } from './QuickCard';
import { IBusinessProfile } from '../types/businessProfile';
import { Container, Row } from 'reactstrap';

export class FeaturedCards extends React.Component {
    props: {
        businesses: IBusinessProfile[],
        title: string,
        tag: string
    }

    render() {
        return (
            <div className="album py-5 bg-light">
                <Container>
                    <h2>{this.props.title}</h2>
                    <Row>
                        { this.props.businesses.map((businessInfo, key) => 
                            <QuickCard key={key} info={businessInfo} tag={this.props.tag} />
                        )} 
                    </Row>
                </Container>
              </div>
            )
        }
}