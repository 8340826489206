import firebase from 'firebase/app';
import 'firebase/firestore';
import { GeoFirestore } from 'geofirestore';
import { IGeoPoint, IGeoSearchResult } from '../types/geoPoint';

const firestore = firebase.firestore();
const geofirestore = new GeoFirestore(firestore);

// Create a GeoCollection reference
const geocollection = geofirestore.collection('locations');

export const DoGeoFirestoreQuery = async (geopt: IGeoPoint, distance: number): Promise<IGeoSearchResult[]> => {
    const query = geocollection.near({
        center: new firebase.firestore.GeoPoint(geopt.lat, geopt.lng),
        radius: distance
    });

    // Get query (as Promise)
    const res: IGeoSearchResult[] =
        await query.limit(10).get()
            .then((documents) => {
                let results: IGeoSearchResult[] = [];

                documents.forEach((doc) => {
                    const searchResult: IGeoSearchResult = {
                        id: doc.id,
                        exists: true,
                        distance: doc.distance,
                        data: doc.data() as any,
                    };
                    results.push(searchResult)
                })

                return results;
            });

    return res;
}
