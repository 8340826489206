import * as React from 'react';
import { Link } from 'react-router-dom';
import { master as firebase } from '../libs/firestore';

interface IProps {
    flip: any,
}
interface IState {
    email: string,
    password: string,
    error: string,
    authenticated: boolean
}
export class Login extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = { email: '', password: '', error: null, authenticated: false };
        this.SignIn = this.SignIn.bind(this);
        firebase.auth().onAuthStateChanged(user => {
            if (!user) {
                this.props.flip();
            }
        });
    }

    SignIn() {
        if (!firebase.auth().currentUser) {
            const { email, password } = this.state;
            firebase.auth()
                .signInWithEmailAndPassword(email, password)
                .then(res => {
                    if (!res.user.emailVerified) {
                        this.setState({ error: 'Please verify your email first' });
                        firebase.auth().signOut();
                    } else {
                        this.setState({ error: null });
                        this.props.flip('success');
                        location.assign('/profile');
                    }
                })
                .catch(err => {
                    console.log(err);
                    switch (err.code) {
                        case 'auth/wrong-password':
                            this.setState({ error: 'Invalid Username or Password' });
                            break;
                        case 'auth/user-not-found':
                            this.setState({ error: 'Please register first' });
                            break;
                        default: this.setState({ error: 'Something went wrong' });
                            break;
                    }
                });
        } else {
            location.assign('/profile');
        }
    }

    render() {
        return (
            // this.state.authenticated ?
            // <Redirect to="/" /> :
            <div className="overlay-content">
                <div className="row">
                    <div className="col-md-12">
                        <img src="/assets/icons/icon-72x72.png" width="72px" height="72px" />
                        <h4 className="pt-4 mb-0">LOGIN</h4>
                        <div className="form-width">
                            <div className="form-group">
                                <input type="email" value={this.state.email}
                                    onChange={e => this.setState({ email: e.target.value.toLowerCase().trim() })} className="form-control login-input" placeholder="Email *" />
                            </div>
                            <div className="form-group">
                                <input type="password" value={this.state.password}
                                    onChange={e => this.setState({ password: e.target.value })} className="form-control login-input" placeholder="Password *" />
                            </div>
                            {this.state.error && <p className="m-0 text-danger min-height24">{this.state.error}</p>}
                            <button onClick={this.SignIn}
                                className="btn btn-login mt-32px btn-block">
                                LOGIN
                            </button>
                            <div className="pt-3">
                                <Link to="#resetPassword"
                                    onClick={() => this.props.flip('#resetPassword')}
                                    className="font-weight-bold font-size14">
                                    RESET MY PASSWORD
                                </Link>
                            </div>
                            <div className="text-bold">
                                <Link to="#register"
                                    onClick={() => this.props.flip('#register')}
                                    className="font-weight-bold font-size14">
                                    CREATE AN ACCOUNT
                                </Link>
                            </div>
                            <div className="mt-5 text-small">
                                1 account for<br />
                                <a href="https://petsitterdashboard.com" target="_blank">Pet Sitter Dashboard</a><br />
                                <a href="https://petsitterprofiles.com">Pet Sitter Profiles</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}