import * as React from 'react';
import { IBusinessProfile } from '../types/businessProfile';
import { Link } from 'react-router-dom';
import { ToTitleCase } from '../libs/helper';

export class QuickCard extends React.Component {
    props: {
        info: IBusinessProfile,
        tag: string
    }

    render() {
        return (
            <div className="col-md-4">
                <div className="card mb-4 shadow-sm">
                    <Link to={"/" + this.props.info.url} className="unstyled-link">
                        <figure className={"figure tag tag-" + this.props.tag}>
                            <img className="bd-placeholder-img card-img-top figure-img" width="100%" height="225" src={this.props.info.image} />
                        </figure>
                        <div className="card-body">
                            <p className="card-text ellipsis">{ToTitleCase(this.props.info.name)}</p>
                            <p className="card-text ellipsis">{ToTitleCase(this.props.info.address.city)}</p>
                        </div>
                    </Link>
                    <div className="card-footer">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="btn-group">
                                <a type="button"
                                    className="btn btn-sm btn-outline-secondary mr-2"
                                    href={"tel:" + this.props.info.telephone}>
                                    <span className="fa fa-envelope"></span> Call
                                </a>
                                <a type="button"
                                    className="btn btn-sm btn-outline-secondary mr-2"
                                    href={"mailto:" + this.props.info.email}>
                                    <span className="fa fa-envelope"></span> Email
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}