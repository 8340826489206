import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Header } from './construction/Header';
import { Main } from './construction/Main';
import { Footer } from './construction/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/app.scss'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { About } from './components/About';
import { Contact } from './components/Contact';
import { FAQ } from './components/FAQ';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { TermsAndConditions } from './components/TermsAndConditions';
import { BusinessDisplay } from './components/BusinessDisplay';
import { SearchResults } from './components/SearchResults';
import { Profile } from './components/Profile';

declare let module: any

ReactDOM.render((
    <Router>
        <Header />
        <Switch>
            <Route path="/about" component={About} />
            <Route path="/contact" component={Contact} />
            <Route path="/faq" component={FAQ} />
            <Route path="/profile" component={Profile} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-and-conditions" component={TermsAndConditions} />
            <Route path="/q/:query" component={SearchResults} />
            <Route path="/:id" component={BusinessDisplay} />
            <Route exact path="/">
                <Main />
            </Route>
        </Switch>
        <Footer />
    </Router>),
    document.getElementById('root'));

if (module.hot) {
    module.hot.accept();
}