import * as React from 'react';
import { master as firebase } from '../libs/firestore';
import { Redirect } from 'react-router-dom';
import { Container } from 'reactstrap';
import { JumboTron } from './JumboTron';

export interface BusinessModel {
    name: string;
    pspCity: string;
    pspPostcode: string;
    country: string;
    pspAbout: string;
    email: string;
    telephone: string;
    website: string;
    facebook: string;
    twitter: string;
    pspVisible: boolean;
    error: string;
    redirect: boolean;
    loading: boolean;
    authenticated: boolean;
}

export const CountryList = [
    { code: 'GB', name: 'United Kingdom', region: 'UK' },
    { code: 'US', name: 'United States of America', region: 'USA' },
    { code: 'CA', name: 'Canada', region: 'Canada' },
    { code: 'AU', name: 'Australia', region: 'Oceania' },
    { code: 'NZ', name: 'New Zealand', region: 'Oceania' },
    { code: 'AT', name: 'Austria', region: 'Europe' },
    { code: 'BY', name: 'Belarus', region: 'Europe' },
    { code: 'BE', name: 'Belgium', region: 'Europe' },
    { code: 'BG', name: 'Bulgaria', region: 'Europe' },
    { code: 'HR', name: 'Croatia', region: 'Europe' },
    { code: 'CY', name: 'Cyprus', region: 'Europe' },
    { code: 'DK', name: 'Denmark', region: 'Europe' },
    { code: 'EE', name: 'Estonia', region: 'Europe' },
    { code: 'FI', name: 'Finland', region: 'Europe' },
    { code: 'FR', name: 'France', region: 'Europe' },
    { code: 'GE', name: 'Georgia', region: 'Europe' },
    { code: 'DE', name: 'Germany', region: 'Europe' },
    { code: 'GI', name: 'Gibraltar', region: 'Europe' },
    { code: 'GR', name: 'Greece', region: 'Europe' },
    { code: 'GG', name: 'Guernsey', region: 'Europe' },
    { code: 'HU', name: 'Hungary', region: 'Europe' },
    { code: 'IS', name: 'Iceland', region: 'Europe' },
    { code: 'IE', name: 'Ireland', region: 'Europe' },
    { code: 'IM', name: 'Isle of Man', region: 'Europe' },
    { code: 'IT', name: 'Italy', region: 'Europe' },
    { code: 'JE', name: 'Jersey', region: 'Europe' },
    { code: 'LV', name: 'Latvia', region: 'Europe' },
    { code: 'LU', name: 'Luxembourg', region: 'Europe' },
    { code: 'MT', name: 'Malta', region: 'Europe' },
    { code: 'MC', name: 'Monaco', region: 'Europe' },
    { code: 'ME', name: 'Montenegro', region: 'Europe' },
    { code: 'NL', name: 'Netherlands', region: 'Europe' },
    { code: 'NO', name: 'Norway', region: 'Europe' },
    { code: 'PL', name: 'Poland', region: 'Europe' },
    { code: 'PT', name: 'Portugal', region: 'Europe' },
    { code: 'MK', name: 'North Macedonia', region: 'Europe' },
    { code: 'RO', name: 'Romania', region: 'Europe' },
    { code: 'RU', name: 'Russia', region: 'Europe' },
    { code: 'SM', name: 'San Marino', region: 'Europe' },
    { code: 'RS', name: 'Serbia', region: 'Europe' },
    { code: 'SK', name: 'Slovakia', region: 'Europe' },
    { code: 'SI', name: 'Slovenia', region: 'Europe' },
    { code: 'ES', name: 'Spain', region: 'Europe' },
    { code: 'SE', name: 'Sweden', region: 'Europe' },
    { code: 'CH', name: 'Switzerland', region: 'Europe' },
    { code: 'TR', name: 'Turkey', region: 'Europe' },
    { code: 'UA', name: 'Ukraine', region: 'Europe' },
];
export class Profile extends React.Component<{}, BusinessModel> {

    user: any;
    unsubscribe: any;
    constructor(props: any) {
        super(props);
        this.state = {name: '', pspCity: '', pspPostcode: '', country: '', twitter: '',
            pspAbout: '', email: '', telephone: '', website: '', facebook: '', pspVisible: true, error: null, redirect: false, loading: true, authenticated: false};
        this.unsubscribe = firebase.auth().onAuthStateChanged(user => {
            if(user){
                this.setState({authenticated: true});
            }
            var getBusiness = firebase.functions().httpsCallable('pspBusinessGet');
            getBusiness().then((result: any) => {
                const { name, pspCity, pspPostcode, country, pspAbout, email, telephone, website, facebook, twitter, pspVisible } = result.data;
                this.setState({ name, pspCity, pspPostcode, country, pspAbout, email, telephone, website, facebook, twitter, pspVisible, loading: false });
            }).catch(error => {
                this.setState({error: error.message, loading: false});
            });
        });
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleUpdate() {
        this.setState({ loading: true });
        const regx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (this.state.name.length < 1) {
            this.setState({ error: "Business name is required", loading: false });
            return;
        } else if (this.state.pspCity.length < 1) {
            this.setState({ error: "Town/City is required", loading: false });
            return;
        } else if (this.state.pspPostcode.length < 1) {
            this.setState({ error: "Postcode/Zipcode is required", loading: false });
            return;
        } else if (this.state.country.length < 1) {
            this.setState({ error: "Country is required", loading: false });
            return;
        } else if (this.state.pspAbout.length < 1) {
            this.setState({ error: "About is required", loading: false });
            return;
        } else if (this.state.email.length < 1 || !regx.test(this.state.email)) {
            this.setState({ error: "Email is not valid", loading: false });
            return;
        } else if (this.state.telephone.length < 1) {
            this.setState({ error: "Telephone is required", loading: false });
            return;
        } else if (this.state.website.length > 0 && (!this.state.website.startsWith('http://') && !this.state.website.startsWith('https://'))) {
            this.setState({ error: "Website address must start http:// or https://", loading: false });
            return;
        } else if (this.state.facebook.length > 0 && !this.state.facebook.startsWith('https://facebook.com/')) {
            this.setState({ error: "Facebook address must start https://facebook.com/", loading: false });
            return;
        } else if (this.state.twitter.length > 0 && !this.state.twitter.startsWith('https://twitter.com/')) {
            this.setState({ error: "Twitter address must start https://twitter.com/", loading: false });
            return;
        }
        var setBusiness = firebase.functions().httpsCallable('pspBusinessSet');
        setBusiness(this.state).then((result: any) => {
            this.setState({ redirect: true, loading: false, error: null });
        }).catch(error => {
            this.setState({ error: error.message, loading: false });
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }
    render() {
        if ((!this.state.loading && !this.state.authenticated) || this.state.redirect) {
            return <Redirect to="/" />
        }
        return (
            <main role="main">
                <JumboTron />
                {this.state.loading ?
                    <Container><em>Loading...</em></Container>
                    :
                    <div className="container">
                        <div className="row mb-4">
                            <div className="col-md-8">
                                <h2 className="my-4">
                                    Profile
                                </h2>
                                <div className="form-box-shadow">
                                    <div className="form-group">
                                        <label>Business Name (required)</label>
                                        <input type="text" value={this.state.name} required
                                            onChange={e => this.setState({ name: e.target.value })}
                                            className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Town/City (required)</label>
                                        <input type="text" value={this.state.pspCity} required
                                            onChange={e => this.setState({ pspCity: e.target.value.trim() })}
                                            className="form-control" />
                                        <label className="tip">Customers will be able to search for this location</label>
                                    </div>
                                    <div className="form-group">
                                        <label>Postcode/Zipcode (required)</label>
                                        <input type="text" value={this.state.pspPostcode} required
                                            onChange={e => this.setState({ pspPostcode: e.target.value.trim() })}
                                            className="form-control" />
                                        <label className="tip">Customers will be able to search for this location</label>
                                    </div>
                                    <div className="form-group">
                                        <label>Country (required)</label>
                                        <select className="form-control" id="selCountry" value={this.state.country}
                                            onChange={e => this.setState({ country: e.target.value.trim() })}>
                                            {CountryList.map((country, key) =>
                                                <option key={key} value={country.code}>{country.name}</option>
                                            )}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>About (required)</label>
                                        <textarea className="form-control" value={this.state.pspAbout} rows={5} required
                                            onChange={e => this.setState({ pspAbout: e.target.value })} id="about"></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label>Email (required)</label>
                                        <input type="text" value={this.state.email} required
                                            onChange={e => this.setState({ email: e.target.value.toLowerCase().trim() })}
                                            className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Telephone (required)</label>
                                        <input type="text" value={this.state.telephone} required
                                            onChange={e => this.setState({ telephone: e.target.value.trim() })}
                                            className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Website</label>
                                        <input type="text" value={this.state.website}
                                            onChange={e => this.setState({ website: e.target.value.toLowerCase().trim() })}
                                            className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Facebook</label>
                                        <input type="text" value={this.state.facebook}
                                            onChange={e => this.setState({ facebook: e.target.value.toLowerCase().trim() })}
                                            className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label>Twitter</label>
                                        <input type="text" value={this.state.twitter}
                                            onChange={e => this.setState({ twitter: e.target.value.toLowerCase().trim() })}
                                            className="form-control" />
                                    </div>
                                    <label>
                                        Please allow up to 12 hours for changes to appear.<br />
                                        All profiles are moderated.<br />
                                        We reserve the right to suspend your account, for any reason, if you abuse this service.<br />
                                    </label>
                                    <div className="form-group text-left">
                                        <div className="mt-2">
                                            <input id="visible" type="checkbox" checked={this.state.pspVisible}
                                                onChange={e => this.setState({ pspVisible: e.target.checked })}
                                                className="font-size14" />
                                            &nbsp;&nbsp;Make my profile visible
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        {this.state.error && <p className="m-0 text-danger min-height24">{this.state.error}</p>}
                                        <button onClick={this.handleUpdate} className="btn btn-login mt-2 btn-block">SUBMIT</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-4"></div>
                        </div>
                    </div>
                }
            </main>
        )
    }
}