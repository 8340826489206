import * as React from 'react';
import { Link } from 'react-router-dom';
import { Login } from './Login';
import { Signup } from './Signup';
import { ResetPassword } from './ResetPassword';
import { master as firebase } from '../libs/firestore';

interface IProps {
    compiler: string,
    framework: string,
    bundler: string
}
interface IState {
    hideModal: boolean,
    login: boolean,
    reset: boolean,
    register: boolean,
    authenticated: boolean
}
export class Header extends React.Component<{}, IState> {

    constructor(props: any) {
        super(props);
        this.state = {
            hideModal: true,
            login: true,
            reset: false,
            register: false,
            authenticated: false
        };
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                this.setState({ authenticated: true, hideModal: true });
            } else {
                this.setState({ authenticated: false });
            }
        });
        this.flipModal = this.flipModal.bind(this);
        this.flipView = this.flipView.bind(this);
    }

    componentDidMount() {
        this.flipView(location.hash)
        window.addEventListener("hashchange", e => this.flipView(location.hash));
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", e => this.flipView(location.hash));
    }

    flipModal() {
        if (!this.state.authenticated) {
            this.setState({ hideModal: !this.state.hideModal, login: true, reset: false, register: false })
        }
    }

    flipView(hashKey: string) {
        switch (hashKey) {
            case '#login':
                !this.state.authenticated && this.setState({ hideModal: false, login: true, reset: false, register: false });
                break;
            case '#resetPassword':
                !this.state.authenticated && this.setState({ hideModal: false, login: false, reset: true, register: false });
                break;
            case '#register':
                !this.state.authenticated && this.setState({ hideModal: false, login: false, reset: false, register: true });
                break;
            case 'success':
                this.state.authenticated && this.setState({ hideModal: true, authenticated: true });
                break;
            default: this.state.authenticated && this.setState({ hideModal: true });
        }
    }

    modalClose = () => {
        if (firebase.auth().currentUser) {
            firebase.auth().signOut();
        }
        this.flipModal();
    }

    render() {
        return (
            <header>
                <div className="navbar navbar-dark bg-dark shadow-sm">
                    <div className="container d-flex justify-content-between">
                        <div>
                            <a href="/" className="navbar-brand d-flex align-items-center">
                                <img src="/assets/icons/icon-72x72.png" width="72px" height="72px" />
                                <strong className="pl-2">Pet Sitter Profiles</strong>
                            </a>
                        </div>
                        <div>
                            <ul className="list-inline list-unstyled mb-0 d-flex flex-nowrap">
                                <li className="list-inline-item mr-4">
                                    <Link className="text-white" to="/">Home</Link>
                                </li>
                                <li className="list-inline-item mr-4">
                                    <Link className="text-white" to="/about">About</Link>
                                </li>
                                <li className="list-inline-item mr-4">
                                    <Link className="text-white" to="/FAQ">FAQ</Link>
                                </li>
                                <li className="list-inline-item mr-4">
                                    <Link className="text-white" to="/contact">Contact</Link>
                                </li>
                                {this.state.authenticated &&
                                    <li className="list-inline-item mr-4">
                                        <Link to="profile" className="text-white">Profile</Link>
                                    </li>
                                }
                                {!this.state.authenticated &&
                                    <li className="list-inline-item mr-4" onClick={this.flipModal}>
                                        <Link to={{ hash: '#login' }} className="text-white">Login</Link>
                                    </li>
                                }
                            </ul>
                        </div>
                        <div id="login-modal" className={!this.state.hideModal ? 'right' : ''}>
                            <Link to={{ hash: '' }} className="close" onClick={this.modalClose}>&times;</Link>
                            {this.state.login && <Login flip={this.flipView}></Login>}
                            {this.state.reset && <ResetPassword flip={this.flipView}></ResetPassword>}
                            {this.state.register && <Signup flip={this.flipView}></Signup>}
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}