import * as React from 'react';
import { Link } from 'react-router-dom';
import { master as firebase } from '../libs/firestore';

interface IProps {
  compiler: string,
  framework: string,
  bundler: string
}

interface IState {
  authenticated: boolean;
}

export class Footer extends React.Component<{}, IState> {

  constructor(props: any) {
    super(props);
    this.state = { authenticated: false };
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ authenticated: true });
      } else {
        this.setState({ authenticated: false });
      }
    });
    this.Signout = this.Signout.bind(this);
  }


  Signout() {
    firebase.auth().signOut();
    location.reload();
  }

  render() {
    return (
      <section id="footer">
        <div className="container">
          <div className="row text-center text-xs-center text-sm-left text-md-left">
            <div className="col-xs-12 col-sm-4 col-md-4">
              <h5>Quick links</h5>
              <ul className="list-unstyled quick-links">
                <li><Link to="/">
                  <i className="fa fa-angle-double-right"></i>Home</Link>
                </li>
                <li>
                  <Link to="/about">
                    <i className="fa fa-angle-double-right"></i>About</Link></li>
                <li>
                  <Link to="/FAQ">
                    <i className="fa fa-angle-double-right"></i>FAQ</Link></li>
                <li>
                  <Link to="/contact">
                    <i className="fa fa-angle-double-right"></i>Contact</Link></li>
                <li>
                  <Link to="/terms-and-conditions">
                    <i className="fa fa-angle-double-right"></i>Terms &amp; Conditions</Link></li>
                <li>
                  <Link to="/privacy-policy">
                    <i className="fa fa-angle-double-right"></i>Privacy Policy</Link></li>
                <li>
                  <a href="https://petsitterdashboard.com/" target="_blank"><i className="fa fa-angle-double-right"></i>Pet Sitter Dashboard</a>
                </li>
                {this.state.authenticated &&
                  <li className="list-inline-item text-white pointer" onClick={this.Signout}>
                    <i className="fa fa-angle-double-right"></i> Logout
                  </li>
                }
              </ul>
              <div className="text-white mt-2">Copyright © 2022 Pet Sitter Profiles</div>
              <div className="text-white">All Rights Reserved</div>
              <div className="text-white">Proudly built in Manchester, UK</div>
            </div>
            <div className="col-xs-12 col-sm-8 col-md-8">
              <h5>Popular locations</h5>
              <ul className="list-unstyled quick-links container row">
                <li className="col-xs-12 col-sm-6"><Link to="/q/birmingham"><i className="fa fa-angle-double-right"></i>Birmingham</Link></li>
                <li className="col-xs-12 col-sm-6"><Link to="/q/cardiff"><i className="fa fa-angle-double-right"></i>Cardiff</Link></li>
                <li className="col-xs-12 col-sm-6"><Link to="/q/exeter"><i className="fa fa-angle-double-right"></i>Exeter</Link></li>
                <li className="col-xs-12 col-sm-6"><Link to="/q/glasgow"><i className="fa fa-angle-double-right"></i>Glasgow</Link></li>
                <li className="col-xs-12 col-sm-6"><Link to="/q/london"><i className="fa fa-angle-double-right"></i>London</Link></li>
                <li className="col-xs-12 col-sm-6"><Link to="/q/manchester"><i className="fa fa-angle-double-right"></i>Manchester</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </section >
    )
  }
}