import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import "firebase/auth";

export const firebaseSlaveConfig = {
  apiKey: "AIzaSyDaPqc9teaI2xuAhOQ8vdO5CJ8T0rmxXD4",
  authDomain: "diddyprofiles.firebaseapp.com",
  databaseURL: "https://diddyprofiles.firebaseio.com",
  projectId: "diddyprofiles",
  storageBucket: "diddyprofiles.appspot.com",
  messagingSenderId: "685155371728",
  appId: "1:685155371728:web:e4730ecc3ba454d323ecd5",
  measurementId: "G-N0ST7N7E95"
};

export const firebaseMasterConfig = {
  apiKey: "AIzaSyDy0OmxJJBRkJQr33l6U3i8rwqhGQ9jtqA",
  authDomain: "diddydashboard.firebaseapp.com",
  databaseURL: "https://diddydashboard.firebaseio.com",
  projectId: "diddydashboard",
  storageBucket: "diddydashboard.appspot.com",
  messagingSenderId: "559574648777",
  appId: "1:559574648777:web:cf9c60c5c9a2d73726ca18"
};

export const slave = firebase.initializeApp(firebaseSlaveConfig);
export const master = firebase.initializeApp(firebaseMasterConfig, 'master');

export default firebase;