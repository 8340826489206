import * as React from 'react';
import { JumboTron } from './JumboTron';

export class Contact extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <main role="main">
                <JumboTron />
                <div className="container my-4">
                    <h2 className="my-4">
                        Contact
                    </h2>
                    <div className="mb-4">
                        To get in touch send an email to <a href="mailto:hello@petsitterprofiles.com">hello@petsitterprofiles.com</a>
                    </div>
                    <div className="mb-4">
                        We aim to reply to all emails within 24 hours during business hours (Monday to Friday)
                    </div>
                </div>
            </main>
        )
    }
}