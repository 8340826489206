import * as React from 'react';
import { IBusinessProfile, BusinessProfileFactory } from '../types/businessProfile';
import admin from '../libs/firestore';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import ServicesList from './ServicesList';
import { ToTitleCase } from '../libs/helper';

export interface ISearchResultState {
    info: IBusinessProfile,
    loading: boolean
}

interface ISearchResultProps {
    id: string,
    distance: number
}

export class SearchResult extends React.Component<ISearchResultProps, ISearchResultState> {
    constructor(props: ISearchResultProps) {
        super(props);
        this.state = {
            info: {
                id: '',
                url: '',
                name: '',
                image: '',
                description: '',
                address: {
                    city: '',
                    postcode: '',
                    country: '',
                    geopt: {
                        lat: 0,
                        lng: 0
                    }
                },
                telephone: '',
                email: '',
                website: '',
                facebook: '',
                twitter: '',
                featured: false,
                registrationDate: '',
                lastChangeDate: '',
                services: [
                    { displayName: '', priceFirstPet: 0, pricePerAdditionalPet: 0 }
                ]
            },
            loading: true
        };
    }

    async componentDidMount() {
        var db = admin.firestore();
        let currentComponent = this;

        db.collection("businessProfiles").doc(this.props.id)
            .get()
            .then((doc: any) => {
                let business = doc.data();
                let thisBusiness = BusinessProfileFactory(business);

                db.collection("businessProfiles").doc(this.props.id).collection("services")
                    .get()
                    .then((servicesSnapshot: any) => {
                        servicesSnapshot.forEach((service: any) => {
                            thisBusiness.services.push(service.data());
                        });
                    })
                    .catch(function (error) {
                        console.error("Error getting business: ", error);
                    })
                    .then(() => {
                        currentComponent.setState({
                            info: thisBusiness,
                            loading: false
                        });
                    });
            })
            .catch(function (error) {
                console.error("Error getting business: ", error);
            });
    };


    render() {
        return this.state.loading
            ? (<em>Loading...</em>)
            : (
                <Link to={"/" + this.state.info.url} className="unstyled-link">
                    <Card className="mb-2">
                        <CardBody >
                            <img src={this.state.info.image} width="100px" className="float-left mr-2" />
                            <div>
                                <span className="text-bold">{ToTitleCase(this.state.info.name)}</span>
                            </div>
                            <div>
                                <small>{this.state.info.address.city} - {this.props.distance.toFixed(1)} kms</small>
                            </div>
                            <div className="ellipsis">
                                <small>{this.state.info.description}</small>
                            </div>
                        </CardBody>
                    </Card>
                </Link>
            );
    }
}
