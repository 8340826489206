import { IGeoPoint } from "../types/geoPoint";

export const DoGeocode = async (query: string): Promise<IGeoPoint> => {
    const geopoint = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + query + ',+UK&key=AIzaSyC82BW73YVSp5hnb9wGDH1fJemeOHCdbIM')
        .then(response => response.json())
        .then(docs => {
            if (docs.status == "ZERO_RESULTS") {
                return {
                    lat: 0,
                    lng: 0
                } as IGeoPoint;
            } else {
                return {
                    lat: docs.results[0].geometry.location.lat,
                    lng: docs.results[0].geometry.location.lng
                } as IGeoPoint;
            }
        })
    return geopoint;
}
