import * as React from 'react';
import { Link } from 'react-router-dom';
import { JumboTron } from './JumboTron';

export class FAQ extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <main role="main">
                <JumboTron />
                <div className="container my-4">
                    <h2 className="my-4">FAQ's</h2>
                    <div>
                        <dl>
                            <dt>How much does it cost to list my business?</dt>
                            <dd className="mb-4"><Link to="/">Pet Sitter Profiles</Link> are completely free to list and search</dd>

                            <dt>Where are you available?</dt>
                            <dd className="mb-4">UK (coming soon to Europe, USA, Canada, Australia)</dd>

                            <dt>How do I create, edit or remove a profile?</dt>
                            <dd className="mb-4">
                                <div className="mb-2">
                                    To create a profile: Tap <strong>Login</strong>, <strong>Create An Account</strong>.
                                </div>
                                <div className="mb-2">
                                    To edit your profile: tap <strong>Profile</strong> (make sure you are logged in).
                                </div>
                                <div className="mb-2">
                                    To remove your profile: tap <strong>Profile</strong> and untick "Make my profile visible".
                                </div>
                                <div className="mb-1">
                                    You can also edit your profile through our sister site
                                    <a href="https://app.petsitterdashboard.com/pet-sitter-profile" target="_blank"> Pet Sitter Dashboard</a>.
                                </div>
                            </dd>

                            <dt>How do I change my picture?</dt>
                            <dd className="mb-4">
                                If you have a <strong>Pet Sitter Dashboard</strong> account&nbsp;
                                <a href="https://app.petsitterdashboard.com/pet-sitter-profile" target="_blank">click here</a>
                            </dd>

                            <dt>How do I claim a profile?</dt>
                            <dd className="mb-4">
                                Send an email to&nbsp;
                                <a href="mailto:hello@petsitterprofiles.com">hello@petsitterprofiles.com</a>
                            </dd>

                            <dt>How do I get my business featured?</dt>
                            <dd className="mb-4">
                                You need to be a member of&nbsp;
                                <a href="https://petsitterdashboard.com/pet-sitter-club/" target="_blank">Pet Sitter Club</a>
                            </dd>

                            <dt>I want to start a dog walking or pet sitting business. Can you help?</dt>
                            <dd className="mb-4">
                                Yes we can. Our sister website&nbsp;
                                <a href="https://petsitterdashboard.com/list" target="_blank">Pet Sitter Dashboard</a>&nbsp;
                                contains a treasure trove of free articles
                            </dd>

                            <dt>What are you service definitions?</dt>
                            <dd className="mb-4">
                                <div>Dog walking = A dog walker comes to your home and walks your dog</div>
                                <div>Pet sitting = A pet sitter comes to your home to feed and fuss your pets</div>
                                <div>Grooming = A pet expert grooms and beautifies your furry friend</div>
                                <div>Doggy day care = Daytime pet care away from the pets home</div>
                                <div>Overnight = Overnight pet care in the pets home</div>
                                <div>Boarding = Overnight pet care away from the pets home</div>
                            </dd>

                            {/* <dt>Who runs this site?</dt>
                            <dd className="mb-4">
                                This site is run by <a href="https://petsitterclub.co.uk" target="_blank">Pet Sitter Club (UK)</a>
                            </dd> */}

                            <dt>How do you use my data?</dt>
                            <dd className="mb-4">
                                Your data belongs to you and we only use it to display your profile. Refer to our&nbsp;
                                <Link to="/privacy-policy">privacy policy</Link> for more information
                            </dd>

                            <dt>How do I contact you?</dt>
                            <dd className="mb-4">Send an email to <a href="mailto:hello@petsitterprofiles.com">hello@petsitterprofiles.com</a></dd>
                        </dl>
                    </div>
                </div >
            </main >
        )
    }
}