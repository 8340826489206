import * as React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { IGeoSearchResult, IGeoPoint } from '../types/geoPoint';
import { DoGeoFirestoreQuery } from '../libs/geofirestore';
import { DoGeocode } from '../libs/geocode';
import { ToTitleCase } from '../libs/helper';
import { SearchResult } from './SearchResult';

export interface ISearchResultsState {
    query: string,
    searchResults: IGeoSearchResult[],
    loading: boolean
}

interface ISearchResultsProps {
    match: any,
    query: string
}

export class SearchResults extends React.Component<ISearchResultsProps, ISearchResultsState> {
    constructor(props: ISearchResultsProps) {
        super(props);
        this.state = {
            query: '',
            loading: true,
            searchResults: []
        };
    }

    async componentDidMount() {
        await this.doSearch();
        window.scrollTo(0, 0);
    }


    async componentDidUpdate() {
        if (!this.state.loading && this.props.match.params.query !== this.state.query) {
            await this.doSearch();
        }
    }

    async doSearch() {
        const { match: { params } } = this.props;
        const queryString = this.props.match.params.query;

        this.setState({
            loading: true
        });

        // geocode the search term
        const geopoint = await DoGeocode(queryString).then((gp: IGeoPoint) => { return gp });

        if (geopoint.lat != 0 && geopoint.lng != 0) {
            // then find some  nearby results
            const firestoreResults = await DoGeoFirestoreQuery(geopoint, 25)
                .then((searchResults) => {
                    searchResults.sort((a, b) => a.distance > b.distance ? 1 : -1);
                    return searchResults
                });

            this.setState({
                searchResults: firestoreResults
            });

        }

        this.setState({
            query: queryString,
            loading: false
        });
    };

    static renderResults(searchResults: IGeoSearchResult[]) {
        return (<div>
            {searchResults.map(
                (searchResult: IGeoSearchResult, key: number) => {
                    return (<SearchResult id={searchResult.data.name} distance={searchResult.distance} key={key} />);
                })
            }
        </div>);
    };

    render() {
        let contents = this.state.loading
            ? <em>Loading...</em>
            : (this.state.searchResults && this.state.searchResults.length > 0
                ? SearchResults.renderResults(this.state.searchResults)
                : <em>No results found</em>);

        return (
            <main role="main">
                <div className="album py-5 bg-light">
                    <Container >
                        <h2>Top 10 results for {ToTitleCase(this.props.match.params.query)}</h2>
                        <Row >
                            <Col>
                                {contents}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </main>
        );
    }
}
