import * as React from 'react';
import { JumboTron } from '../components/JumboTron';
import { FeaturedCards } from '../components/FeaturedCards';
import firebase from '../libs/firestore';
import { IBusinessProfile, BusinessProfileFactory } from '../types/businessProfile';

// import RunningDog from './../img/running-dog.jpg';
// import RunningDogWithBall from './../img/running-dog-ball.jpg';
// import DogWithGlasses from './../img/dog-glasses.jpg';

interface IMainState {
  featuredBusinesses: IBusinessProfile[];
  recentBusinesses: IBusinessProfile[];
  loading: boolean
}

interface IProps {
}

export class Main extends React.Component<{}, IMainState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      featuredBusinesses: [],
      recentBusinesses: [],
      loading: true
    }
  }

  async componentDidMount() {
    var db = firebase.firestore();
    let currentComponent = this;

    db.collection("businessProfiles")
      .where("featured", "==", true)
      .orderBy("lastChangeDate", "desc")
      .limit(3)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc: any) {
          let featuredBusiness = BusinessProfileFactory(doc.data());
          currentComponent.setState({
            featuredBusinesses: [...currentComponent.state.featuredBusinesses, doc.data()]
          })
        });
      })
      .catch(function (error) {
        console.error("Error getting documents: ", error);
      });

    db.collection("businessProfiles")
      .orderBy("lastChangeDate", "desc")
      .limit(3)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc: any) {
          let recentBusinesses = BusinessProfileFactory(doc.data());
          currentComponent.setState({
            recentBusinesses: [...currentComponent.state.recentBusinesses, doc.data()]
          })
        });
      })
      .catch(function (error) {
        console.error("Error getting documents: ", error);
      });

    window.scrollTo(0, 0);
  }

  render() {
    return (
      <main role="main">
        <JumboTron />
        <FeaturedCards businesses={this.state.featuredBusinesses} title="Featured" tag="featured" />
        <FeaturedCards businesses={this.state.recentBusinesses} title="Recent" tag="recent" />
      </main>
    )
  }
}