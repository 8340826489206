import * as React from 'react';
import { SearchBox } from './SearchBox';

// interface IProps {
//     compiler: string,
//     framework: string,
//     bundler: string
// }

export class JumboTron extends React.Component {
    render() {
        return (
            <section className="jumbotron text-center">
                <div className="container" >
                    <p className="lead">Find a local dog walker or pet sitter</p>
                    <SearchBox />
                </div>
            </section>
          )
    }
}