import * as React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

interface IState {
  searchval: string,
  doSearch: boolean
}

interface IProps {
}

export class SearchBox extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = { searchval: '', doSearch: false };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event: any) {
    this.setState({ searchval: event.target.value });
  }

  handleSubmit(event: any) {
    this.setState({ doSearch: true });
  }

  handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      this.handleSubmit(event);
    }
  }

  render() {
    if (this.state.doSearch) {
      return <Redirect to={'/q/' + this.state.searchval} />
    } else {
      return (
        <div className="container h-100">
          <div className="d-flex justify-content-center h-100">
            <div className="searchbar d-flex justify-content-between">
              <input className="search_input" type="text" name="" placeholder="Search by town or postcode..."
                value={this.state.searchval} onChange={this.handleChange} onKeyDown={this.handleKeyDown} />
              <a className="search_icon d-flex justify-content-center" onClick={this.handleSubmit}><i className="fas fa-search"></i></a>
            </div>
          </div>
        </div>
      )
    }
  }
}