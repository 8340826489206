import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import firebase from '../libs/firestore';

interface IProps {
    flip: any
}
interface IState {
    email: string,
    error: string,
    success: boolean,
    authenticated: boolean
}
export class ResetPassword extends React.Component<IProps, IState> {

    constructor(props: any) {
        super(props);
        this.state = { email: '', error: null, success: null, authenticated: false };
        this.resetLink = this.resetLink.bind(this);
        firebase.auth().onAuthStateChanged(user => {
            this.setState({ authenticated: (user ? true : false) });
        });
    }

    resetLink() {
        firebase.auth().sendPasswordResetEmail(this.state.email).then(res => {
            this.setState({ success: true })
        }).catch(error => {
            this.setState({ error: error.message, success: null })
        });
    }

    render() {
        return (
            this.state.authenticated ?
                <Redirect to="/" /> :
                <div className="overlay-content">
                    <div className="row">
                        <div className="col-md-12">
                            <img src="/assets/icons/icon-72x72.png" width="72px" height="72px" />
                            <h4 className="pt-4 mb-0">RESET MY PASSWORD</h4>
                            <div className="form-width">
                                <div className="form-group">
                                    <input type="email" value={this.state.email}
                                        onChange={e => this.setState({ email: e.target.value.toLowerCase().trim() })} className="form-control login-input" placeholder="Email *" />
                                </div>
                                {this.state.success && <p className="m-0 text-success min-height24">Link sent successfully.<br />Check mailbox.</p>}
                                {this.state.error && <p className="m-0 text-danger min-height24">{this.state.error}</p>}
                                <button onClick={this.resetLink} className="btn btn-login mt-32px btn-block">SEND RESET LINK</button>
                                <p className="mb-0 pt-3">
                                    <Link to="#login" onClick={() => this.props.flip('#login')} className="font-weight-bold font-size14">GO BACK TO LOGIN</Link>
                                </p>
                                <div className="mt-5 text-small">
                                    1 account for<br />
                                    <a href="https://petsitterdashboard.com" target="_blank">Pet Sitter Dashboard</a><br />
                                    <a href="https://petsitterprofiles.com">Pet Sitter Profiles</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}