import * as React from 'react';
import { IBusinessProfile, BusinessProfileFactory } from '../types/businessProfile';
import admin from '../libs/firestore';
import { Row, Col, Container, Media } from 'reactstrap';
import BusinessMap from './BusinessMap';
import ServicesList from './ServicesList';
import { ToTitleCase } from '../libs/helper';
import firebase from '../libs/firestore';

export interface IBusinessDisplayState {
    info: IBusinessProfile,
    loading: boolean
}

interface IProps {
    match: any,
    history: any
}

const imgStyle = {
    maxHeight: 300,
    maxWidth: 300
}

export class BusinessDisplay extends React.Component<IProps, IBusinessDisplayState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            info: {
                id: '',
                url: '',
                name: '',
                image: '',
                description: '',
                address: {
                    city: '',
                    postcode: '',
                    country: '',
                    geopt: {
                        lat: 0,
                        lng: 0
                    }
                },
                telephone: '',
                email: '',
                website: '',
                facebook: '',
                twitter: '',
                featured: false,
                registrationDate: '',
                lastChangeDate: '',
                services: [
                    { displayName: '', priceFirstPet: 0, pricePerAdditionalPet: 0 }
                ]
            },
            loading: true
        }
    }

    componentDidMount() {
        const { } = this.props;

        var db = firebase.firestore();
        let currentComponent = this;
        const url = encodeURIComponent(this.props.match.params.id);
        db.collection("businessProfiles").where('url', '==', url)
            .get()
            .then((qs: admin.firestore.QuerySnapshot) => {
                if (qs.docs.length > 0) {

                    let business = qs.docs[0].data();
                    let thisBusiness = BusinessProfileFactory(business as IBusinessProfile);

                    db.collection("businessProfiles").doc(this.props.match.params.id).collection("services")
                        .get()
                        .then((servicesSnapshot: any) => {
                            servicesSnapshot.forEach((service: any) => {
                                thisBusiness.services.push(service.data());
                            });
                        })
                        .catch(function (error) {
                            console.error("Error getting business: ", error);
                        })
                        .then(() => {
                            currentComponent.setState({
                                info: thisBusiness,
                                loading: false
                            });
                        });
                } else {
                    this.props.history.push('/');
                }
            })
            .catch(function (error) {
                console.error("Error getting business: ", error);
                this.props.history.push('/');
            });

        window.scrollTo(0, 0);
    }

    static renderBusiness(business: IBusinessProfile) {
        return (
            <Container className="mt-3 mb-3 mt-sm-5 mb-sm-5 dark">
                <Row>
                    <Col xs="12" sm="auto">
                        <Media>
                            <Media left>
                                <Media object src={business.image} alt={business.name} style={imgStyle} />
                            </Media>
                        </Media>
                    </Col>
                    <Col>
                        <Media body>
                            <Media heading className="mt-3 mt-sm-0">
                                {ToTitleCase(business.name)}
                            </Media>
                        </Media>
                        <div>
                            <svg className="bi bi-geo-alt" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M8 16s6-5.686 6-10A6 6 0 002 6c0 4.314 6 10 6 10zm0-7a3 3 0 100-6 3 3 0 000 6z" clipRule="evenodd" />
                            </svg>
                            <span className="ml-2">
                                {business.address.city}, {business.address.country}
                            </span>
                        </div>
                        <div className="mt-2">
                            <a href={"tel:" + business.telephone}>{business.telephone.toLowerCase()}</a>
                        </div>
                        <div className="mt-2">
                            <ul className="list-inline list-unstyled social">
                                {!!business.email &&
                                    <li className="list-inline-item mr-4 text-center">
                                        <a href={"mailto:" + business.email}>
                                            <i className="fa fa-envelope"></i>
                                            <div>Email</div>
                                        </a>
                                    </li>
                                }
                                {!!business.website &&
                                    <li className="list-inline-item mr-4 text-center">
                                        <a href={business.website}>
                                            <i className="fa fa-globe"></i>
                                            <div>Website</div>
                                        </a>
                                    </li>
                                }
                                {!!business.facebook &&
                                    <li className="list-inline-item mr-4 text-center">
                                        <a href={business.facebook}>
                                            <i className="fab fa-facebook-square"></i>
                                            <div>Facebook</div>
                                        </a>
                                    </li>
                                }
                                {!!business.twitter &&
                                    <li className="list-inline-item mr-4 text-center">
                                        <a href={business.twitter}>
                                            <i className="fab fa-twitter-square"></i>
                                            <div>Twitter</div>
                                        </a>
                                    </li>
                                }
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-1 mt-sm-4">
                    <Col className="pre-line-white-space">{business.description}</Col>
                </Row>
                {business.services.length > 0 &&
                    <Row className="mt-4">
                        <Col className="pre-line-white-space"><ServicesList services={business.services} /></Col>
                    </Row>
                }
                <Row className="mt-4">
                    <Col><BusinessMap center={{ lat: business.address.geopt.lat, lng: business.address.geopt.lng }} zoom={13} /></Col>
                </Row>
            </Container>
        );
    }

    render() {
        let contents = this.state.loading
            ? <Container><em>Loading...</em></Container>
            : BusinessDisplay.renderBusiness(this.state.info);

        return (
            <main role="main">
                {contents}
            </main>
        )
    }
}
