import * as React from 'react';
import { IService } from '../types/service';

interface IProps {
  services: IService[]
}

function intersperse(arr: string[], sep: string) {
  if (arr.length === 0) {
      return [];
  }

  return arr.slice(1).reduce(function(xs, x, i) {
      return xs.concat([sep, x]);
  }, [arr[0]]);
}

const ServicesList = (props: IProps) => {
    let servicesList = props.services.map(service => {
            return service.displayName;
          });

    return (
      <div>{ intersperse(servicesList, ", ") }</div>
    );
} 

export default ServicesList