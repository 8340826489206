
import * as React from 'react';
import { useState } from 'react';
import GoogleMapReact from 'google-map-react';
import MapMarker from './MapMarker';

interface IProps {
  center: {
    lat:number, 
    lng: number
  },
  zoom: number
}

const BusinessMap = (props: IProps) => {
    return (
        <div style={{ height: '35vh', width: '100%' }}>
        <GoogleMapReact bootstrapURLKeys={{ key: 'AIzaSyC82BW73YVSp5hnb9wGDH1fJemeOHCdbIM' }}
                        defaultCenter={props.center}
                        center={props.center}
                        defaultZoom={props.zoom} >
          <MapMarker  lat={props.center.lat}
                      lng={props.center.lng}
                      name="My Marker"
                      color="blue"/>
        </GoogleMapReact>
      </div>
    );
} 

export default BusinessMap