import * as React from 'react';
import { JumboTron } from './JumboTron';

export class PrivacyPolicy extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <main role="main">
                <JumboTron />
                <div className="container my-4" >
                    <h2 className="my-4">
                        Privacy Policy
                    </h2>
                    <div className="mb-2">
                        Thank you for choosing to be part of our community at Pet Sitter Profiles (“company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at hello@petsitterprofiles.com.
                    </div>
                    <div className="mb-2">
                        When you visit our website https://petsitterprofiles.com, mobile application, and use our services, you trust us with your personal information. We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Sites or Apps and our services.
                    </div>
                    <div className="mb-2">
                        This privacy policy applies to all information collected through our website (such as https://petsitterprofiles.com), mobile application, ("Apps"), and/or any related services, sales, marketing or events (we refer to them collectively in this privacy policy as the "Sites").
                    </div>
                    <div className="mb-2">
                        Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.
                    </div>
                    <div className="mb-2">
                        TABLE OF CONTENTS
                    </div>
                    <div className="mb-2">
                        1. WHAT INFORMATION DO WE COLLECT?
                    </div>
                    <div className="mb-2">
                        2. HOW DO WE USE YOUR INFORMATION?
                    </div>
                    <div className="mb-2">
                        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                    </div>
                    <div className="mb-2">
                        4. WHO WILL YOUR INFORMATION BE SHARED WITH?
                    </div>
                    <div className="mb-2">
                        5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </div>
                    <div className="mb-2">
                        6. DO WE USE GOOGLE MAPS?
                    </div>
                    <div className="mb-2">
                        7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </div>
                    <div className="mb-2">
                        8. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                    </div>
                    <div className="mb-2">
                        9. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </div>
                    <div className="mb-2">
                        10. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </div>
                    <div className="mb-2">
                        11. DO WE COLLECT INFORMATION FROM MINORS?
                    </div>
                    <div className="mb-2">
                        12. WHAT ARE YOUR PRIVACY RIGHTS?
                    </div>
                    <div className="mb-2">
                        13. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </div>
                    <div className="mb-2">
                        14. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </div>
                    <div className="mb-2">
                        15. DO WE MAKE UPDATES TO THIS POLICY?
                    </div>
                    <div className="mb-2">
                        16. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                    </div>
                    <div className="mb-2">
                        1. WHAT INFORMATION DO WE COLLECT?
                    </div>
                    <div className="mb-2">
                        Personal information you disclose to us
                    </div>
                    <div className="mb-2">
                        In Short: We collect personal information that you provide to us such as name, address, contact information, passwords and security data, payment information, and social media login data.
                    </div>
                    <div className="mb-2">
                        We collect personal information that you voluntarily provide to us when registering at the Sites or Apps, expressing an interest in obtaining information about us or our products and services, when participating in activities on the Sites or Apps (such as posting messages in our online forums or entering competitions, contests or giveaways) or otherwise contacting us.
                    </div>
                    <div className="mb-2">
                        The personal information that we collect depends on the context of your interactions with us and the Sites or Apps, the choices you make and the products and features you use. The personal information we collect can include the following:
                    </div>
                    <div className="mb-2">
                        Name and Contact Data. We collect your first and last name, email address, postal address, phone number, and other similar contact data.
                    </div>
                    <div className="mb-2">
                        Credentials. We collect passwords, password hints, and similar security information used for authentication and account access.
                    </div>
                    <div className="mb-2">
                        Payment Data. We collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by our payment processor and you should review its privacy policies and contact the payment processor directly to respond to your questions.
                    </div>
                    <div className="mb-2">
                        Social Media Login Data. We provide you with the option to register using social media account details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we will collect the Information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS" below.
                    </div>
                    <div className="mb-2">
                        All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.
                    </div>
                    <div className="mb-2">
                        Information automatically collected
                    </div>
                    <div className="mb-2">
                        In Short: Some information – such as IP address and/or browser and device characteristics – is collected automatically when you visit our Sites or Apps.
                    </div>
                    <div className="mb-2">
                        We automatically collect certain information when you visit, use or navigate the Sites or Apps. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Sites or Apps and other technical information. This information is primarily needed to maintain the security and operation of our Sites or Apps, and for our internal analytics and reporting purposes.
                    </div>
                    <div className="mb-2">
                        Like many businesses, we also collect information through cookies and similar technologies.
                    </div>
                    <div className="mb-2">
                        Information collected through our Apps
                    </div>
                    <div className="mb-2">
                        In Short: We may collect information regarding your geo-location, mobile device, push notifications, when you use our apps.
                    </div>
                    <div className="mb-2">
                        If you use our Apps, we may also collect the following information:
                    </div>
                    <div className="mb-2">
                        Geo-Location Information. We may request access or permission to and track location-based information from your mobile device, either continuously or while you are using our mobile application, to provide location-based services. If you wish to change our access or permissions, you may do so in your device’s settings.
                    </div>
                    <div className="mb-2">
                        Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device’s camera, and other features. If you wish to change our access or permissions, you may do so in your device’s settings.
                    </div>
                    <div className="mb-2">
                        Push Notifications. We may request to send you push notifications regarding your account or the mobile application. If you wish to opt-out from receiving these types of communications, you may turn them off in your device’s settings.
                    </div>
                    <div className="mb-2">
                        Information collected from other sources
                    </div>
                    <div className="mb-2">
                        In Short: We may collect limited data from public databases, marketing partners, social media platforms, and other outside sources.
                    </div>
                    <div className="mb-2">
                        We may obtain information about you from other sources, such as public databases, joint marketing partners, social media platforms (such as Facebook), as well as from other third parties. Examples of the information we receive from other sources include: social media profile information (your name, gender, birthday, email, current city, state and country, user identification numbers for your contacts, profile picture URL and any other information that you choose to make public); marketing leads and search results and links, including paid listings (such as sponsored links).
                    </div>
                    <div className="mb-2">
                        2. HOW DO WE USE YOUR INFORMATION?
                    </div>
                    <div className="mb-2">
                        In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
                    </div>
                    <div className="mb-2">
                        We use personal information collected via our Sites or Apps for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests ("Business Purposes"), in order to enter into or perform a contract with you ("Contractual"), with your consent ("Consent"), and/or for compliance with our legal obligations ("Legal Reasons"). We indicate the specific processing grounds we rely on next to each purpose listed below.
                    </div>
                    <div className="mb-2">
                        We use the information we collect or receive:
                    </div>
                    <div className="mb-2">
                        To facilitate account creation and logon process.If you choose to link your account with us to a third party account *(such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process. See the section below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS" for further information.
                    </div>
                    <div className="mb-2">
                        To send you marketing and promotional communications. We and/or our third party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).
                    </div>
                    <div className="mb-2">
                        To send administrative information to you. We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.
                    </div>
                    <div className="mb-2">
                        To post testimonials. We post testimonials on our Sites or Apps that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and testimonial. If you wish to update, or delete your testimonial, please contact us at hello@petsitterprofiles.com and be sure to include your name, testimonial location, and contact information.
                    </div>
                    <div className="mb-2">
                        Administer prize draws and competitions. We may use your information to administer prize draws and competitions when you elect to participate in competitions.
                    </div>
                    <div className="mb-2">
                        Request Feedback. We may use your information to request feedback and to contact you about your use of our Sites or Apps.
                    </div>
                    <div className="mb-2">
                        To protect our Sites. We may use your information as part of our efforts to keep our Sites or Apps safe and secure (for example, for fraud monitoring and prevention).
                    </div>
                    <div className="mb-2">
                        To enable user-to-user communications. We may use your information in order to enable user-to-user communications with each user's consent.
                    </div>
                    <div className="mb-2">
                        To enforce our terms, conditions and policies.
                    </div>
                    <div className="mb-2">
                        To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
                    </div>
                    <div className="mb-2">
                        For other Business Purposes. We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Sites or Apps, products, services, marketing and your experience.
                    </div>
                    <div className="mb-2">
                        3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                    </div>
                    <div className="mb-2">
                        In Short: We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.
                    </div>
                    <div className="mb-2">
                        We may process or share data based on the following legal basis:
                        Consent: We may process your data if you have given us specific consent to use your personal information in a specific purpose.
                    </div>
                    <div className="mb-2">
                        Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.
                    </div>
                    <div className="mb-2">
                        Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
                    </div>
                    <div className="mb-2">
                        Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
                    </div>
                    <div className="mb-2">
                        Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
                        More specifically, we may need to process your data or share your personal information in the following situations:
                    </div>
                    <div className="mb-2">
                        Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the Sites or Apps, which will enable them to collect data about how you interact with the Sites or Apps over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content and better understand online activity. Unless described in this Policy, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.
                    </div>
                    <div className="mb-2">
                        Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                        4. WHO WILL YOUR INFORMATION BE SHARED WITH?
                    </div>
                    <div className="mb-2">
                        In Short:  We only share information with the following third parties.
                    </div>
                    <div className="mb-2">
                        We only share and disclose your information with the following third parties. We have categorized each party so that you may be easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us.
                    </div>
                    <div className="mb-2">  Advertising, Direct Marketing, and Lead Generation
                    </div>
                    <div className="mb-2">  Google AdSense
                    </div>
                    <div className="mb-2">  Content Optimisation
                    </div>
                    <div className="mb-2">  Google Site Search
                    </div>
                    <div className="mb-2">  Functionality and Infrastructure Optimisation
                    </div>
                    <div className="mb-2">  Cloud Firestore, Cloud Storage for Firebase and Firebase Hosting
                    </div>
                    <div className="mb-2">  Invoice and Billing
                    </div>
                    <div className="mb-2">  ChargeBee and Stripe
                    </div>
                    <div className="mb-2">  User Account Registration and Authentication
                    </div>
                    <div className="mb-2">  Firebase Authentication
                    </div>
                    <div className="mb-2">  Web and Mobile Analytics
                    </div>
                    <div className="mb-2">  Google Analytics and Google Analytics for Firebase
                    </div>
                    <div className="mb-2">  Website Testing
                    </div>
                    <div className="mb-2">  Google Play Console and TestFlight
                    </div>
                    <div className="mb-2">
                        5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </div>
                    <div className="mb-2">
                        In Short: We may use cookies and other tracking technologies to collect and store your information.
                        </div>
                    <div className="mb-2">
                        We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Policy.
                    </div>
                    <div className="mb-2">
                        6. DO WE USE GOOGLE MAPS?
                    </div>
                    <div className="mb-2">
                        In Short: Yes, we use Google Maps for the purpose of providing better service.
                    </div>
                    <div className="mb-2">
                        This website, mobile application, or Facebook application uses Google Maps APIs. You may find the Google Maps APIs Terms of Service here. To better understand Google’s Privacy Policy, please refer to this link.
                    </div>
                    <div className="mb-2">
                        By using our Maps API Implementation, you agree to be bound by Google’s Terms of Service.
                    </div>
                    <div className="mb-2">
                        7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </div>
                    <div className="mb-2">
                        In Short: If you choose to register or log in to our websites using a social media account, we may have access to certain information about you.
                    </div>
                    <div className="mb-2">
                        Our Sites or Apps offer you the ability to register and login using your third party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social media provider concerned, but will often include your name, e-mail address, friends list, profile picture as well as other information you choose to make public.
                    </div>
                    <div className="mb-2">
                        We will use the information we receive only for the purposes that are described in this privacy policy or that are otherwise made clear to you on the Sites or Apps. Please note that we do not control, and are not responsible for, other uses of your personal information by your third party social media provider. We recommend that you review their privacy policy to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites and apps.
                    </div>
                    <div className="mb-2">
                        8. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
                    </div>
                    <div className="mb-2">
                        In Short: We may transfer, store, and process your information in countries other than your own.
                    </div>
                    <div className="mb-2">
                        Our servers are located in United States. If you are accessing our Sites or Apps from outside United States, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WILL YOUR INFORMATION BE SHARED WITH ANYONE?" above), in United States, and other countries.
                    </div>
                    <div className="mb-2">
                        If you are a resident in the European Economic Area, then these countries may not have data protection or other laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy policy and applicable law.
                    </div>
                    <div className="mb-2">
                        EU-U.S. Privacy Shield Framework:
                    </div>
                    <div className="mb-2">
                        In particular Pet Sitter Profiles complies with the EU-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information transferred from the European Union to the United States and has certified its compliance with it. As such, Pet Sitter Profiles is committed to subjecting all personal information received from European Union (EU) member countries, in reliance on the Privacy Shield Framework, to the Framework’s applicable Principles. To learn more about the Privacy Shield Framework, visit the U.S. Department of Commerce’s Privacy Shield List.
                    </div>
                    <div className="mb-2">
                        Pet Sitter Profiles is responsible for the processing of personal information it receives, under the Privacy Shield Framework, and subsequently transfers to a third party acting as an agent on its behalf.
                    </div>
                    <div className="mb-2">
                        With respect to personal information received or transferred pursuant to the Privacy Shield Framework, Pet Sitter Profiles is subject to the regulatory enforcement powers of the U.S. FTC. In certain situations, we may be required to disclose personal information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.
                    </div>
                    <div className="mb-2">
                        9. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </div>
                    <div className="mb-2">
                        In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.
                    </div>
                    <div className="mb-2">
                        We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this policy will require us keeping your personal information for longer than the period of time in which users have an account with us.
                    </div>
                    <div className="mb-2">
                        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
                    </div>
                    <div className="mb-2">
                        10. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </div>
                    <div className="mb-2">
                        In Short: We aim to protect your personal information through a system of organisational and technical security measures.
                    </div>
                    <div className="mb-2">
                        We have implemented appropriate technical and organisational security measures designed to protect the security of any personal information we process. However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our Sites or Apps is at your own risk. You should only access the services within a secure environment.
                    </div>
                    <div className="mb-2">
                        11. DO WE COLLECT INFORMATION FROM MINORS?
                    </div>
                    <div className="mb-2">
                        In Short: We do not knowingly collect data from or market to children under 18 years of age.
                    </div>
                    <div className="mb-2">
                        We do not knowingly solicit data from or market to children under 18 years of age. By using the Sites or Apps, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Sites or Apps. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we have collected from children under age 18, please contact us at hello@petsitterprofiles.com.
                    </div>
                    <div className="mb-2">
                        12. WHAT ARE YOUR PRIVACY RIGHTS?
                    </div>
                    <div className="mb-2">
                        In Short: In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time.
                    </div>
                    <div className="mb-2">
                        In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the contact details provided below. We will consider and act upon any request in accordance with applicable data protection laws.
                    </div>
                    <div className="mb-2">
                        If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal.
                    </div>
                    <div className="mb-2">
                        If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                    </div>
                    <div className="mb-2">
                        Account Information
                    </div>
                    <div className="mb-2">
                        If you would at any time like to review or change the information in your account or terminate your account, you can:
                    </div>
                    <div className="mb-2">
                        ■  Log into your account settings and update your user account.
                    </div>
                    <div className="mb-2">
                        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, some information may be retained in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with legal requirements.
                    </div>
                    <div className="mb-2">
                        Cookies and similar technologies:  Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Sites or Apps. To opt-out of interest-based advertising by advertisers on our Sites or Apps visit http://www.aboutads.info/choices/.
                    </div>
                    <div className="mb-2">
                        Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we will still need to send you service-related emails that are necessary for the administration and use of your account. To otherwise opt-out, you may:
                    </div>
                    <div className="mb-2">
                        ■  Note your preferences when you register an account with the site.
                    </div>
                    <div className="mb-2">
                        13. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </div>
                    <div className="mb-2">
                        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. No uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Policy.
                    </div>
                    <div className="mb-2">
                        14. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </div>
                    <div className="mb-2">
                        In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
                    </div>
                    <div className="mb-2">
                        California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
                    </div>
                    <div className="mb-2">
                        If you are under 18 years of age, reside in California, and have a registered account with the Sites or Apps, you have the right to request removal of unwanted data that you publicly post on the Sites or Apps. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Sites or Apps, but please be aware that the data may not be completely or comprehensively removed from our systems.
                    </div>
                    <div className="mb-2">
                        15. DO WE MAKE UPDATES TO THIS POLICY?
                    </div>
                    <div className="mb-2">
                        In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.
                    </div>
                    <div className="mb-2">
                        We may update this privacy policy from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.
                    </div>
                    <div className="mb-2">
                        16. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                    </div>
                    <div className="mb-2">
                        If you have questions or comments about this policy, you may contact our Data Protection Officer (DPO), Darryl Brown, by email at hello@petsitterprofiles.com
                    </div>
                </div>
            </main>
        )
    }
}