import * as React from 'react';
import { Link } from 'react-router-dom';
import firebase from '../libs/firestore';
import { firebaseMasterConfig } from '../libs/firestore';
import { Redirect } from 'react-router-dom';

interface IProps {
    flip: any
}
interface IState {
    success: boolean,
    email: string,
    password: string,
    acceptTerms: boolean,
    error: string
    authenticated: boolean
}
export class Signup extends React.Component<IProps, IState> {
    authApp: any;
    constructor(props: any) {
        super(props);
        this.state = { success: false, email: '', password: '', acceptTerms: false, error: null, authenticated: false };
        this.signup = this.signup.bind(this);
        this.resendVerificationEmail = this.resendVerificationEmail.bind(this);
        firebase.auth().onAuthStateChanged(user => {
            this.setState({ authenticated: (user ? true : false) });
        });
    }

    signup() {
        const { email, password, acceptTerms } = this.state;

        if (!acceptTerms) {
            this.setState({ error: "You must accept the terms and conditions" });
            return;
        }

        this.setState({ error: "" });
        try {
            this.authApp = firebase.initializeApp(firebaseMasterConfig, 'authApp').auth();
        } catch (err) {
            if (!/already exists/.test(err.message)) {
                console.error('Firebase initialization error', err.stack)
            }
        }
        this.authApp.createUserWithEmailAndPassword(email, password)
            .then((res: any) => {
                res.user.sendEmailVerification()
                    .then(() => {
                        this.setState({ success: true, password: '' })
                    })
                    .catch((error: any) => this.setState({ error: error.message }));
            })
            .catch((error: any) => this.setState({ error: error.message }));
    }

    resendVerificationEmail() {
        this.authApp.currentUser.sendEmailVerification().then(() => {
            alert('Verification email sent again.')
        }).catch((error: any) => this.setState({ error: error.message }));
    }

    render() {
        return (
            this.state.authenticated ?
                <Redirect to="/" /> :
                <div className="overlay-content">
                    <div className="row">
                        {!this.state.success ?
                            <div className="col-md-12">
                                <img src="/assets/icons/icon-72x72.png" width="72px" height="72px" />
                                <h4 className="pt-4 mb-0">CREATE AN ACCOUNT</h4>
                                <div className="form-width">
                                    <div className="form-group">
                                        <input type="email"
                                            value={this.state.email}
                                            onChange={e => this.setState({ email: e.target.value.toLowerCase().trim() })}
                                            className="form-control login-input"
                                            placeholder="Email" />
                                    </div>
                                    <div className="form-group">
                                        <input type="password"
                                            value={this.state.password}
                                            onChange={e => this.setState({ password: e.target.value })}
                                            className="form-control login-input"
                                            placeholder="Password" />
                                    </div>
                                    <div className="form-group text-left">
                                        <input type="checkbox"
                                            checked={this.state.acceptTerms}
                                            onChange={e => this.setState({ acceptTerms: e.target.checked })} />
                                        &nbsp;&nbsp;I have read &amp; accept the terms and conditions
                                    </div>
                                    {this.state.error && <p className="m-0 text-danger min-height24">{this.state.error}</p>}
                                    <button onClick={this.signup} className="btn btn-login mt-32px btn-block">
                                        CREATE AN ACCOUNT
                                    </button>
                                    <p className="mb-0 pt-3">
                                        <Link to="#login" onClick={() => this.props.flip('#login')} className="font-weight-bold font-size14">
                                            GO BACK TO LOGIN
                                        </Link>
                                    </p>
                                    <div className="mt-5 text-small">
                                        1 account for<br />
                                        <a href="https://petsitterdashboard.com" target="_blank">Pet Sitter Dashboard</a><br />
                                        <a href="https://petsitterprofiles.com">Pet Sitter Profiles</a>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="col-md-12" >
                                <img src="/assets/icons/icon-72x72.png" width="72px" height="72px" />
                                <h4 className="pt-4">VERIFY YOUR EMAIL ADDRESS</h4>
                                {this.state.error && <p className="m-0 text-danger min-height24">{this.state.error}</p>}
                                <p className="pt-4">A verification email has been sent to</p>
                                <p><b>{this.state.email}</b></p>
                                <p className="pt-4">Check your inbox(and spam folder)</p>
                                <p>Click the link to verify your email address</p>
                                <p className="pt-4">Wait 5 minutes before trying to resend the verification email</p>
                                <div className="form-width">
                                    <Link to={{ hash: '#login' }} onClick={() => this.props.flip('#login')} className="btn btn-login btn-block">LOGIN</Link>
                                </div>
                                <p className="pt-4">
                                    <Link to="" onClick={this.resendVerificationEmail} className="font-weight-bold font-size14">RESEND VERIFICATION EMAIL</Link>
                                </p>
                                <div className="mt-5 text-small">
                                    1 account for<br />
                                    <a href="https://petsitterdashboard.com" target="_blank">Pet Sitter Dashboard</a><br />
                                    <a href="https://petsitterprofiles.com">Pet Sitter Profiles</a>
                                </div>
                            </div>
                        }
                    </div>
                </div>

        )
    }
}