import * as React from 'react';
import { JumboTron } from './JumboTron';
import { Link } from 'react-router-dom';

export class About extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <main role="main">
                <JumboTron />
                <div className="container my-4">
                    <h2 className="my-4">
                        About
                    </h2>
                    <div className="mb-4">
                        <div className="d-inline-flex mb-5">
                            <div className="mr-5">
                                <img src="/assets/dog-glasses-small.jpg" width="100px" height="100px" className="rounded-circle" />
                            </div>
                            <div>
                                <h3>
                                    Trusted
                                </h3>
                                <p>
                                    <Link to="/">Pet Sitter Profiles</Link> is operated by the <a href="https://petsitterclub.co.uk">Pet Sitter Club</a>,
                                    whose purpose is to promote and grow the pet professional community.
                                    <Link to="/"> Pet Sitter Profiles</Link> is our way of giving back to the community.
                                </p>
                                <p>
                                    Pet sitters, dog walkers, groomers, trainers, kennels and boarders are welcome to advertise their services.
                                </p>
                            </div>
                        </div>
                        <div className="d-inline-flex mb-5">
                            <div className="mr-5">
                                <img src="/assets/running-dog-ball-small.jpg" width="100px" height="100px" className="rounded-circle" />
                            </div>
                            <div>
                                <h3>
                                    Free to List, Free to Search
                                </h3>
                                <p className="mh-8">
                                    Our website is free for businesses and customers. This is possible because we keep costs low and pass on the savings to you.
                                </p>
                            </div>
                        </div>
                        <div className="d-inline-flex mb-5">
                            <div className="mr-5">
                                <img src="/assets/sleeping-dog-small.jpg" width="100px" height="100px" className="rounded-circle" />
                            </div>
                            <div>
                                <h3>
                                    Reliable
                                </h3>
                                <p className="mh-8">
                                    <Link to="/">Pet Sitter Profiles</Link> reviews all profiles to ensure that all the pet sitters listed meet our minimum standards.
                                    Where a profile contains an endorsement you can be confident we have checked and verified the claim. Look our for our
                                    "Verified by PSC" logo and check the date the claim was last verified.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        )
    }
}