import { DateTime } from "luxon";
import { IGeoPoint } from "./geoPoint";
import { IService } from "./service";

export interface IBusinessProfile {
    id: string;

    name: string;
    email: string;
    telephone: string;

    address: IAddress;

    website: string;
    facebook: string;
    twitter: string;

    description: string;
    image: string;

    url: string;
    featured: boolean;
    services: IService[];

    registrationDate: string;
    lastChangeDate: string;
}

export interface IAddress {
    city: string;
    postcode: string;
    country: string;
    geopt: IGeoPoint;
}

export function BusinessProfileFactory(source: IBusinessProfile): IBusinessProfile {
    const result = {} as IBusinessProfile;
    result.id = source.id || GenerateGUID();

    result.name = source.name || '';
    result.email = source.email || '';
    result.telephone = source.telephone || '';

    result.address = {} as IAddress;
    result.address.city = source.address?.city || '';
    result.address.country = source.address?.country || '';
    result.address.postcode = source.address?.postcode || '';
    result.address.geopt = {} as IGeoPoint;
    result.address.geopt.lat = source.address?.geopt?.lat || 0;
    result.address.geopt.lng = source.address?.geopt?.lng || 0;

    result.website = source.website || '';
    result.facebook = source.facebook || '';
    result.twitter = source.twitter || '';

    result.description = source.description || '';
    result.image = source.image || '';

    result.url = source.url || '';
    result.featured = source.featured || false;
    result.services = source.services || [];

    result.registrationDate = source.registrationDate || DateTime.local().toISODate(); // YYYY-MM-DD
    result.lastChangeDate = DateTime.local().toISODate(); // YYYY-MM-DD

    return result;
}

function GenerateGUID(): string {
    function S4(): string {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return S4() + S4() + S4() + S4() + S4() + S4() + S4();
}
